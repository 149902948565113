import { graphql } from 'gatsby';
import React from 'react';
import SEO from '../components/SEO';
import Nav from '../components/Nav';
import styled from 'styled-components';
import Header from '../components/Header';
import ContactForm from '../components/ContactForm';
import facebook from '../assets/images/facebook.svg';
import whatsapp from '../assets/images/whatsapp.svg';
import phone_call from '../assets/images/phone-call.svg';
import instagram from '../assets/images/instagram.svg';
import email from '../assets/images/email.svg';
import marco from '../assets/images/Marco.svg';

const ContactStyle = styled.div`
  padding: 2rem 6rem 2rem 6rem;
  width: 70%;
  margin: 0 auto;

  h2 {
    padding-top: 3rem;
    //background-color: var(--color_0);
    border-radius: 3px;
    font-style: italic;
  }

  .titulo-contacto {
    text-align: center;
    padding-bottom: 1rem;
  }

  .grid-contacto {
    padding-top: 2rem;
    margin: 0 auto;
    width: 100%;
    display: grid;
    //grid-template-columns: auto auto;
    gap: 2rem;
    grid-template-rows: auto auto;
    grid-template-columns: auto;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      font-size: 2.5rem;
    }

    li {
      padding-top: 1rem;
    }
  }

  .texto-contacto {
    //border-right: 1px solid lightgray;
    //padding-right: 1rem;
    //padding-bottom: 2rem;
    text-align: center;
    p {
        margin-top: 0;
    }
  }

  .container-links {
    //margin-left: 2rem;
    //margin-left: 0;
    margin: 0 auto;
    width: 450px;
    height: 250px;
    //padding-top: 2rem;
    text-align: center;
    //border: 1px solid var(--color_1);
    //padding-bottom: 3rem;
    //padding: 6rem 10rem 6rem 10rem;
    background-repeat: no-repeat;
    background-image: url(${marco});
    background-size: contain;
    background-position: center;
    display: grid;
    grid-template-rows: 3rem 7rem;
    grid-template-columns: minmax(0, 300px);
    align-content: center;
    justify-content: center;
    img:hover {
      transform: scale(1.2, 1.2);
    }
  }

  .links-contacto {
    padding-top: 2rem;
    display: grid;
    //grid-template-columns: minmax(60px, 50px) minmax(60px, 45px);
    //grid-template-rows: auto auto auto;
    row-gap: 2rem;
    align-items: center;
    justify-items: center;
    grid-template-columns: auto auto auto auto auto;
    grid-template-rows: auto;

  }

  .titulo-form {
      padding-top: 4rem;
      padding-bottom: 2rem;
      font-size: 2.5rem;
      text-align: center;
    }

  .contenedor-mapa {
    text-align: center;

    h2 {
      padding-bottom: 3rem;
    }

    .map-responsive{
      margin: 0 auto;
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;
      height: 0;
      max-width: 700px;
      border: 1px solid var(--color_0);
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    .map-responsive iframe{
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }

    p {
      padding-top: 2rem;
    }
  }

  @media (max-width: 800px) {
    width: 90vw;
    padding: 0 1rem 1rem 1rem;
    margin: 0 auto;

    .container-links {
      margin-left: 0 auto;
      width: 90vw;
      max-height: 30vh;
      //padding-top: 2rem;
      text-align: center;
      //border: 1px solid var(--color_1);
      grid-template-rows: 3rem 5rem;
      grid-template-columns: minmax(0, 220px);

    }

    .grid-contacto {
      grid-template-rows: auto auto;
      grid-template-columns: auto;
      row-gap: 2rem;
    }

    .texto-contacto {
      border-right: none;
      //padding-right: 0;
      p {
        margin-top: 0;
      }
    }

    .links-contacto {
      /*
      padding-left: 0;
      padding-right: 0;
      padding-top: 2rem;
      padding-bottom: 2rem;
      margin: 0 auto;
      width: 50%;
      row-gap: 2rem;
      */
      grid-template-columns: auto auto auto auto auto;
      grid-template-rows: auto;
      padding-bottom: 2.5rem;
    }

    /*
    .quinto-link{
      grid-column: 1 / span 2;
    }
    */
  };
`;

export default function ContactPage({ data }) {
  return (
    <>
      <SEO title="Bio" />
      <Header />
      <Nav categorias={data.categorias.nodes}/>
      <ContactStyle>
        <h2 className="titulo-contacto">Contacto</h2>
        <div className="hand-drawn-divider-2"></div>
        <div className="grid-contacto">
          <div className="texto-contacto">
            <p>Distintas opciones para nuestro primer contacto:</p>
            <div className="container-redes">
              <ul>
                <li>Teléfono / Whatsapp: 11-3435-1210</li>
                <li>e-mail: laura.milberg@gmail.com</li>
              </ul>
            </div>
          </div>
          <div className="container-links">
            <h3>O hacé click aquí:</h3>
            <div className="links-contacto">
              <a className="link-icono" href="tel:11-3435-1210"><img src={phone_call} align="center" alt="Llamar" height="30px"/></a>
              <a className="link-icono" href="https://wa.me/5491134351210"><img src={whatsapp} align="center" alt="Whatsapp" height="30px"/></a>
              <a className="link-icono" href="mailto:laura.milberg@gmail.com"><img src={email} align="center" alt="email" height="30px"/></a>
              <a className="link-icono" href="https://www.facebook.com/psicologa.en.flores.laura"><img src={facebook} align="center" alt="Facebook" height="30px"/></a>
              <a className="link-icono quinto-link" href="https://www.instagram.com/voz.propia.psi/"><img src={instagram} align="center" alt="Instagram" height="30px"/></a>
            </div>
          </div>
        </div>
        <h2 className="titulo-form">También podés enviarme un mensaje por aquí:</h2>
        <ContactForm />
        <div className="hand-drawn-divider-2"></div>
        <div className="contenedor-mapa">
          <h2>Mi consultorio:</h2>
          <div className="map-responsive">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6929.2274672053145!2d-58.464240774577945!3d-34.6280769989859!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdcfc8c318201eb32!2sLic.%20Laura%20Milberg!5e0!3m2!1ses-419!2sar!4v1611222637073!5m2!1ses-419!2sar" width="600" height="400" frameBorder="0" style={{border:"0"}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
          </div>
          <p>
             Mi consultorio se encuentra en el barrio de Flores, en la esquina de Ramón Falcón y Carabobo. Muy cerca de la estación "Carabobo" de la línea "A" de subte.
          </p>
          <p>
            Debido a las condiciones generadas por la pandemia, actualmente me encuentro trabajando de manera remota.
          </p>
        </div>
      </ContactStyle>
    </>
  );
}

export const query = graphql`
  query
  {
    categorias: allSanityCategory {
      nodes {
        title
      }
    }
  }
`;
