import React from "react"
import styled from 'styled-components';

const ContactFormStyles = styled.div`

  width: 90%;
  margin: 0 auto;
  padding-bottom: 2rem;

  .form-fields-container {
    margin-top: 2rem;
    display: grid;
    grid-gap: 2rem;
    grid-template-rows: auto auto auto;
  }

  .field-container {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 10rem auto;
  }

  label {
    justify-self: left;
    align-self: center;
  }

  input, textarea {
    justify-self: left;
    width: 85%;
    border: 2px solid var(--color_3);
    outline: none;
    border-radius: 5px;
    padding: 8px;
  }

  input:focus, textarea:focus {
    border: 2px solid var(--color_7);
  }

  textarea {
    height: 20rem;
  }

  button {
    margin: 0 auto;
    padding: 0;
    justify-self: center;
    box-shadow: none;
    text-shadow: none;
    outline:none;
    color: black;
    font-size: 2.5rem;
    font-weight: 500;
    text-decoration: none;
    display: block;
    width: 15rem;
    background-color: var(--color_6);
    line-height: 6rem;
    margin-top: 2rem;
    width: 10rem;
    transition: 0.3s;
  }

  button:hover {
    color: white;
    //font-weight: bold;
    background-color: var(--color_7);
  }

  h2 {
    font-size: 2.5rem;
  }

  @media (max-width: 800px) {
    padding: 2 rem;
    width: 100%;

    .form-fields-container {
      width: 100%;
      margin: 0 auto;
    }

    .field-container {
      column-gap: 1rem;
      grid-template-columns: 9rem auto;
    }
  }
`;

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

export default class ContactForm extends React.Component {
  state = {
    fullName: "",
    email: "",
    message: ""
  }
  
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }
  
  handleSubmit = event => {

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "Formulario de contacto", ...this.state })
    })
      .then(() => alert(`Mensaje enviado, ${this.state.fullName}!`))
      .catch(error => alert(error));
    event.preventDefault();
  }
  
  render() {
    return (
      <ContactFormStyles>
        <form onSubmit={this.handleSubmit} netlify-honeypot="bot-field" data-netlify="true" name="Formulario de contacto">
          <input type="hidden" name="form-name" value="Formulario de contacto" />
          <div className="form-fields-container">
            <div className="field-container">
              <label htmlFor="id_name_input">
                Nombre:
              </label>
              <input
                id="id_name_input"
                type="text"
                name="fullName"
                value={this.state.fullName}
                onChange={this.handleInputChange}
                required
              />
            </div>
            <div className="field-container">
              <label htmlFor="id_email_input">
                e-mail:
              </label>
              <input
                id="id_email_input"
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleInputChange}
                required
              />
            </div>
            <div className="field-container">
              <label htmlFor="id_message_input">
                Mensaje:
              </label>
              <textarea
                id="id_message_input"
                type="text"
                name="message"
                value={this.state.message}
                onChange={this.handleInputChange}
                required
              />
            </div>
          </div>
          <button type="submit">Enviar</button>
        </form>
      </ContactFormStyles>
    )
  }
}